<template>
  <div>
    <b-card>
      <b-row>
        <b-col sm="2">
          <h6>Lote Asignación</h6>
        </b-col>
        <b-col sm="2">
          <h6>Fecha de finalización</h6>
        </b-col>
        <b-col sm="2">
          <h6>Creditos Asignados</h6>
        </b-col>
        <b-col sm="2">
          <h6>Creditos Pendientes</h6>
        </b-col>
        <b-col sm="2">
          <h6>Creditos Finalizados</h6>
        </b-col>
        <b-col sm="2">
          <h6>Valor Pendiente</h6>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="2" class="d-flex">
          <feather-icon icon="UserPlusIcon" size="20" class="mt-2" />
          <h6 class="mt-2 ml-2">{{ assignmentId }}</h6>
        </b-col>
        <b-col sm="2" class="d-flex">
          <feather-icon icon="CalendarIcon" size="20" class="mt-2" />
          <h6 class="mt-2 ml-2">{{ dueDate }}</h6>
        </b-col>
        <b-col sm="2" class="d-flex">
          <feather-icon icon="ClipboardIcon" size="20" class="mt-2" />
          <h6 class="mt-2 ml-2">{{ assignmentsConcepts }}</h6>
        </b-col>
        <b-col sm="2" class="d-flex">
          <feather-icon icon="ClipboardIcon" size="20" class="mt-2" />
          <h6 class="mt-2 ml-2">{{ penddingCredits }}</h6>
        </b-col>
        <b-col sm="2" class="d-flex">
          <feather-icon icon="ClipboardIcon" size="20" class="mt-2" />
          <h6 class="mt-2 ml-2">{{ finishedCredits }}</h6>
        </b-col>
        <b-col sm="2" class="d-flex">
          <feather-icon icon="BookmarkIcon" size="20" class="mt-2" />
          <h6 class="mt-2 ml-2">{{ status }}</h6>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Buscar</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Buscar"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field === 'action'" class="text-nowrap">
            <RouterLink
              class="user__more"
              :to="{
                path: `/quality/productivity/batch/${id}/type-credit/${props.row.id}`,
              }"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
            </RouterLink>
            <b-button
              v-if="downloadCreditsFinishedLoading"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              class="btn-icon rounded-circle ml-1"
            >
              <b-spinner small/>
            </b-button>
            <b-button
              v-else
              @click="downloadCreditsFinished(props.row)"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              class="btn-icon rounded-circle ml-1"
            >
              <feather-icon icon="DownloadIcon" />
            </b-button>
          </div>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                {{ props.total }} registros totales
              </span>
            </div>

            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>
    
<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
  BRow,
  BCol,
  BSpinner,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
    BSpinner,
  },
  data() {
    return {
      rows: [],
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: "Tipo Acreencia",
          field: "type_credit",
          filterOptions: {
            enabled: true,
            placeholder: "Buscar tipo acreencia",
          },
        },
        {
          label: "Cantidad Acreencias",
          field: "quantity",
          filterOptions: {
            enabled: true,
            placeholder: "Buscar cantidad acreencias",
          },
        },
        {
          label: "Cantidad Creditos",
          field: "quantityCredits",
          filterOptions: {
            enabled: true,
            placeholder: "Buscar cantidad creditos",
          },
        },
        {
          label: "Valor Asignado",
          field: "value",
          filterOptions: {
            enabled: true,
            placeholder: "Buscar valor asignado",
          },
        },
        {
          label: "Creditos Pendientes",
          field: "penddingAssignments",
          filterOptions: {
            enabled: true,
            placeholder: "Buscar creditos pendientes",
          },
        },
        {
          label: "Valor Pendiente",
          field: "penddingValue",
          filterOptions: {
            enabled: true,
            placeholder: "Buscar valor pendiente",
          },
        },
        {
          label: "Creditos Finalizados",
          field: "finishedAssignments",
          filterOptions: {
            enabled: true,
            placeholder: "Buscar creditos finalizados",
          },
        },
        {
          label: "Valor Finalizado",
          field: "finishedValue",
          filterOptions: {
            enabled: true,
            placeholder: "Buscar valor finalizado",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      searchTerm: "",
      userData: JSON.parse(localStorage.getItem("userData")),
      assignmentId: null,
      assignmentsConcepts: null,
      assignmentsValue: null,
      penddingValue: null,
      assigmentPendding: null,
      dueDate: null,
      id: this.$route.params && this.$route.params.id,
      assignmentBatchId: null,
      penddingCredits: null,
      finishedCredits: null,
      status: null,
      downloadCreditsFinishedLoading: false,
    };
  },
  methods: {
    fetchTypeOfCredits() {
      this.$http
        .get("/quality/productivity/batch/" + this.id)
        .then((response) => {
          this.rows = response.data.result;
          this.totalRows = response.data.total;
          this.assignmentId = response.data.assignmentBatch.id;
          this.dueDate = response.data.assignmentBatch.dueDate;
          this.assignmentsConcepts = response.data.assignmentBatch.totalCredits;

          this.penddingCredits = response.data.assignmentBatch.penddingCredits;
          this.finishedCredits = response.data.assignmentBatch.finishedCredits;
          this.status = response.data.assignmentBatch.status;
          this.assignmentBatchId = response.data.assignmentBatch.id;
        });
    },
    downloadCreditsFinished(assignmentBatch) {
      this.downloadCreditsFinishedLoading = true
      //this.$http.post('/quality/productivity/batch/')
    },
  },
  created() {
    this.fetchTypeOfCredits();
  },
  directives: {
    Ripple,
  },
};
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>